import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"

import PikselFs from "../../../components/fullscreen/PikselFs"
import Seo from "../../../components/Seo"
import OluPikselGaleri from "../../../contents/Gallery/OluPikselGaleri"
import SvgLeftIcon from "../../../contents/Icons/SvgLeftIcon"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
import SvgPixelTest from "../../../contents/SVG/SvgPixelTest"
import EndIndicator from "../components/EndIndicator"
import StartIndicator from "../components/StartIndicator"
const DeadPixelTest = () => {
  const refPikselFs = useRef()

  return (
    <>
      <Seo
        lang="es"
        keywords="Prueba de píxeles muertos, prueba de píxeles atascados, reparación de píxeles atascados, reparación de píxeles muertos, prueba de píxeles atascados, lo que es píxel muerto, lo que se atasca Pixel, lo que está atascado Pixel, la diferencia de píxeles de píxeles muertos, prueba de píxeles muertos., Pixel muerto, Pixel Test, Pixel atascado, Pixel Repair Online"
        title="Prueba de píxel muerto y atascado"
        description="Pruebe píxeles muertos y píxeles atascados en sus Android, teléfonos para iPhone, Tablet, PC, televisión inteligente, monitor y pantalla de computadora en el sitio gratuito sin ninguna instalación."
      />
      <h1 className="text-center md:text-left">
        Pixel muerto o prueba pixel pegajosa
      </h1>
      <p className="lead">
        Puede detectar píxeles muertos o atascados en su pantalla, si los hay,
        haciendo clic en el botón{" "}
        <code className="capitalize">Probar píxeles muertos - atascados</code> a
        continuación sin ninguna configuración.
      </p>

      <div
        className="px-4 py-3 text-blue-900 bg-blue-100 border-t-4 border-blue-500 rounded-b shadow-md"
        role="alert"
      >
        <p className="font-bold">
          Antes de comenzar la prueba de píxeles muertos, debe considerar:
        </p>
        <p className="">
          Antes de hacer la prueba de píxeles muertos, asegúrese de que su
          pantalla esté limpia y Su entorno no es demasiado brillante. Mientras
          que el polvo en su pantalla puede engañado, el brillo excesivo puede
          hacer que usted pierda píxeles muertos.
        </p>
      </div>

      <div className="mt-10 text-center">
        <PurpleSolidButton
          text="Probar Píxeles Muertos - Atascados"
          onClick={() => refPikselFs.current.open()}
        />
      </div>
      <h2>¿Cómo puedo probar píxeles muertos o píxeles pegados?</h2>
      <p>
        Después de hacer clic en el botón{" "}
        <code className="capitalize">Probar píxeles muertos - atascados</code>{" "}
        de arriba, el sitio pasará a pantalla completa. La información está
        disponible en la pantalla que se abre.
      </p>
      <p>
        Para detectar píxeles muertos o atascados, debe verificar los colores
        rojo, verde, azul, negro y blanco que ocupan la pantalla completa uno
        por uno.
      </p>

      <SvgPixelTest className="max-w-full px-3 md:px-10" />
      <p>
        Para cambiar entre colores mientras está en pantalla completa, puede
        hacer clic en cualquier parte de la pantalla con el botón izquierdo del
        mouse o presionar las teclas{" "}
        <SvgLeftIcon className="inline text-purple-700 fill-current" /> y{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" />{" "}
        (izquierda y derecha) de su teclado.
      </p>
      <blockquote>
        Antes de iniciar el píxel muerto - prueba de pixel pegado, por favor,
        cuidado de advertencia Sobre el cuadro de información azul en la parte
        superior.
      </blockquote>
      <p>
        Si detecta un punto o puntos con un color diferente al color de fondo
        que cubre su pantalla, significa que tiene un píxel muerto o un píxel
        atascado en su pantalla.
      </p>

      <blockquote>
        Puede presionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para salir de la prueba.
      </blockquote>
      <p>
        Puede ver imágenes de muestra de píxeles muertos o píxeles atascados
        haciendo clic en el botón{" "}
        <code className="capitalize">
          Muestras de píxeles muertos - atascados
        </code>{" "}
        a continuación.
      </p>
      <OluPikselGaleri text="Muestras de píxeles muertos - atascados" />
      <h2>¿Qué es el píxel muerto?</h2>
      <p>
        <strong>Dead Pixel</strong> es un píxel que no funciona en tu pantalla
        porque no recibe energía. Por esta razón, los píxeles muertos suelen ser
        de color negro.
      </p>
      <p>
        Aunque los píxeles muertos son en su mayoría negros, pueden ser blancos
        en algunas pantallas.
      </p>
      <p>
        Para obtener más información sobre la reparación de píxeles muertos y
        píxeles muertos, puede ir a la página correspondiente haciendo clic en
        el botón{" "}
        <code className="capitalize">Reparación de píxeles muertos</code> a
        continuación.
      </p>

      <div className="my-3 text-center">
        <Link
          to="/correccion-de-pixeles-muertos"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">
            Reparación de píxeles muertos
          </span>
        </Link>
      </div>

      <h2>¿Qué es píxel atascado?</h2>
      <p className="lead">
        A diferencia de los píxeles muertos, los píxeles atascados aún obtienen
        energía, por esta razón, reparar la reparación de píxeles atascados es
        mucho más fácil.
      </p>
      <p>
        Los píxeles atascados son píxeles que permanecen en su mayoría en
        colores básicos como <span className="text-red-600">rojo</span>,{" "}
        <span className="text-green-400">verde</span>,{" "}
        <span className="text-blue-600">azul</span> por razones tales como
        permanecer del mismo color durante mucho tiempo y reflejar el mismo
        color continuamente.
      </p>
      <p>
        Para obtener más información sobre píxeles atascados y reparación de
        píxeles atascados, puede ir a la página correspondiente haciendo clic en
        el botón{" "}
        <code className="capitalize">Reparación de píxeles atascados</code> a
        continuación.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correccion-de-pixeles-atascados"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Reparación de píxeles atascados</span>
        </Link>
      </div>
      <PikselFs
        ref={refPikselFs}
        startIndicator={<StartIndicator />}
        endIndicator={
          <EndIndicator
            closeTest={() => refPikselFs.current.close()}
            resetTest={() => refPikselFs.current.reset()}
          />
        }
      />
    </>
  )
}
export default DeadPixelTest
