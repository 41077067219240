import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
import RedSolidButton from "../../../components/Buttons/RedSolidButton"
const EndIndicator = ({ closeTest, resetTest }) => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        <span role="img" aria-label="Felicidades">
          🎉
        </span>
        Has completado con éxito la prueba de píxeles muertos
        <span role="img" aria-label="Felicidades">
          🎉
        </span>
      </p>
      <p className="lead">Espero que no haya píxeles muertos en su pantalla.</p>
      <p className="lead">
        Puede presionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para salir de la prueba.
      </p>
      <div>
        <RedSolidButton
          text="Salida"
          onClick={closeTest}
          style={{ marginRight: "8px" }}
        />
        <PurpleSolidButton text="Retener" onClick={resetTest} />
      </div>
    </div>
  )
}
export default memo(EndIndicator)
