import React, { memo } from "react"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
const StartIndicator = () => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        Puede iniciar la prueba de píxeles muertos haciendo clic en cualquier
        lugar de la pantalla o presionando la tecla{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" />{" "}
        (derecha) de su teclado.
      </p>
      <p className="lead">
        Puede presionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para salir de la prueba.
      </p>
    </div>
  )
}
export default memo(StartIndicator)
